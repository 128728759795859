<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />

        <v-card class="card">
            <v-card-title class="heading justify-center">Bulk Reset PRN</v-card-title>
            <v-card-text>
                <div class="add-section">
                    <v-row class="justify-content-between set-btn">
                        <div><a href="">
                                <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export CSV
                                    Template</v-btn></a>
                        </div>
                    </v-row>
                    <br>
                    <!-- <p class="file-warn justify-content-start">NOTE: *File should be in CSV format.</p> -->
                    <v-form ref="form">
                        <v-row class="justify-content-center">
                            <v-col lg="5" sm="12" md="5" class="import-box">
                                <label class="label"> Action/UserType </label><span
                                    style="color: red; font-weight: bolder">*</span>
                                <v-select class="text" :items="action_list" v-model="selected_action" item-text="name"
                                    item-value="id" autocomplete="off" outlined dense clearable required></v-select>
                                <label class="label">IMPORT CSV</label><span
                                    style="color: red; font-weight: bolder">*</span>
                                <v-file-input dense v-model="file" persistent-hint clearable color="rgb(1, 127, 152)"
                                    label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size>
                                </v-file-input>


                                <v-btn :loading="output_load" @click="importCsv" class="button">
                                    Import
                                    <v-icon right>mdi-cloud-upload</v-icon>
                                </v-btn>

                            </v-col>
                            <v-col lg="5" sm="12" md="5" class="import-box">
                                <label class="label"> Action/UserType </label><span
                                    style="color: red; font-weight: bolder">*</span>
                                <v-select class="text" :items="action_list" v-model="selected_action1" item-text="name"
                                    item-value="id" autocomplete="off" @change="getoldprn()" outlined dense clearable
                                    required></v-select>
                                <label class="add-text">Old PRN</label><span style="color: red;"> *</span>
                                <v-select class="text" :items="oldprnlist" v-model="oldprnformaction" item-text="old"
                                    item-value="old_Id" autocomplete="off" outlined dense clearable required></v-select>
                                <label class="add-text">NEW PRN</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="new_prn" outlined dense clearable
                                    required></v-text-field>

                                <v-btn @click="save" class="button">
                                    Save

                                </v-btn>

                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <template>

                    <h5 v-if="failList.length > 0" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                    <v-btn v-if="failList.length > 0" id="export" @click="exportexcel()" color="success"
                        style="float:right;" dark>
                        <v-icon dark>mdi-file-excel</v-icon>
                    </v-btn>
                    <v-simple-table v-if="failList.length > 0" id="exceltable">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>Old PRN</th>
                                    <th>New PRN</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name" style="color:red;">
                                    <th>{{ item.row }} </th>
                                    <th>{{ item.old_prn }} </th>
                                    <th>{{ item.new_prn }} </th>
                                    <th>{{ item.msg }} </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

                <template>
                    <h5 v-if="success_list.length > 0" class="mt-4" style="text-align: center; color: green;">Success Record
                    </h5>
                    <v-btn v-if="success_list.length > 0" id="export" @click="exportexcel1()" color="success"
                        style="float:right;" dark>
                        <v-icon dark>mdi-file-excel</v-icon>
                    </v-btn>
                    <v-simple-table v-if="success_list.length > 0" id="exceltable1">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>Old PRN</th>
                                    <th>New PRN</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in success_list" :key="item.name" style="color:green;">
                                    <th>{{ item.row }} </th>
                                    <th>{{ item.old_prn }} </th>
                                    <th>{{ item.new_prn }} </th>
                                    <th>{{ item.msg }} </th>

                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>





            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css";
export default {
    data: () => ({
        valid: false,
        overlay: false,
        failList: [],
        success_list: [],
        oldprnlist: [],
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Bulk Reset Prn',
                disabled: true,
                href: 'breadcrumbs_link_2',
            },
        ],
        action_list: [

            {
                id: "1",
                name: 'Employee'
            }
            , { id: "2", name: 'Learner' }],
        selected_action: "",
        selected_action1: "",
        snackbar_msg: "",
        color: "",
        old_prn: "",
        new_prn: "",
        oldprnformaction: "",
        snackbar: false,
        search: "",
        file: null,
        dialog: false,
        fileStatus: true,


        isLoading: false,
        isLoading1: false,
        output_load: false,
        rules: {
            required: (value) => !!value || "Required.",
        },
    }),

    methods: {
        importCsv() {

            let formData = new FormData();


            if (this.selected_action == '') {
                this.showSnackbar("red", "Please Select Action");
                return
            }
            if (this.file == null) {
                this.showSnackbar("red", "Please Select File");
                return
            }
            if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                this.showSnackbar("red", "File must be in CSV format Only.!");
                return
            }

            this.fileStatus = true;
            formData.append("file", this.file);
            formData.append("selected_action", this.selected_action)
            this.isLoading = true;
            axios
                .post("ImportData/bulkresetprn", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {

                    this.failList = res.data.failList;
                    this.success_list = res.data.success_list;
                    this.isLoading = false;
                    this.file = null;
                    if (res.data.status == "200") {
                        this.showSnackbar("#4CAF50", "File Imported successfully...");
                        this.$refs.form.reset()
                    }
                })




        },
        save() {
            if (this.selected_action1 == '') {
                this.showSnackbar("red", "Please Select Action");
                return
            }

            if (this.oldprnformaction == '') {
                this.showSnackbar("red", "Please Enter Old Prn No...!");
                return
            }
            if (this.new_prn == '') {
                this.showSnackbar("red", "Please Enter New Prn No...!");
                return
            }

            let formData = new FormData();
            formData.append("selected_action", this.selected_action1);
            formData.append("old_prn", this.oldprnformaction);
            formData.append("new_prn", this.new_prn);
            this.isLoading1 = true;
            axios
                .post("ImportData/bulkresetprnforsingle", formData, {

                }).then((res) => {
                    this.isLoading = false;
                    if (res.data.status == "200") {
                        this.showSnackbar("#4CAF50", "PRN No Update Successfully...!")

                        this.$refs.form.reset()
                    } else if (res.data.status == "201") {
                        this.showSnackbar("red", res.data.msg)
                    } else {
                        this.showSnackbar("red", "Something Went Wrong...!")
                    }
                })




        },

        getoldprn() {
            let formData = new FormData();
            formData.append("selected_action", this.selected_action1);
            axios
                .post("/ImportData/getoldprn", formData, {
                }).then((res) => {
                    if (res.data.msg == "200") {
                        this.oldprnlist = res.data.oldprnlist;
                    } else {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        close() {
            this.dialog = false;
        },
    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}

.add-section {
    display: block;
}

.import-box {
    margin: 1rem 1rem 2rem 1rem;
    border: 2px solid;
    padding: 1rem 2rem 1rem 2rem;
    text-align: center;
    background: #ffffffb5;
}

.label {
    color: black;
    font-size: 16px;
    font-weight: 400;
}

.button {
    background: white !important;
}

.set-btn {
    margin: 0rem 1rem 0rem 1rem;
    padding-top: 1rem;
}

.button:hover {
    color: white;
    background: black !important;
}

.file-warn {
    font-style: italic;
    color: red;
    text-align: center;
    margin: 0;
}
</style>
